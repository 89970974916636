<template>
  <div class="">
    <div class="about-title listItemTitle">
      {{ $t("about.security.title") }}
    </div>
    <div class="about-content" v-html="$t('about.security.content')"></div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped></style>
